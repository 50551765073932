import React from "react";

export const RoadmapCard = () => {
  const ROADMAP_DATA = [
    {
      year: 2021,
      q1: "Bee Trade Finance Concept & Blockchain Development",
      q2: "Bee Trade Website Launch & release of First White Paper",
      q3: "Security Audit by Certik",
      q4: "Engagement with BlockSol LLC for Project Structuring",
    },
    {
      year: 2022,
      q1: "Year-In Review Development of Native Bee Trade Blockchain Platform - Closed Alpha Testing - Market Makers Can Integrate and Test",
      q2: "Signed Agreements with BlockSol LLC as Chief Advisor for Bee Trade Finance",
      q3: "Business Set Up - Business Legal Structure Formation - Team Formation - BTF DEX Main Net testing - Promotion in Blockchain and Crypto Events",
    },
    {
      year: 2023,
      q1: "Bee Trade DEX Live – Public-beta launch of Bee Trade Finance",
      q2: "$BTF Token Listing on Major Exchanges. Release of BTF Blockchain White Paper and Website",
      q3: " BTF Token Sale to Create BTF Governance Fund. BTF Blockchain DAO Launch – On-chain Voting Mechanism Based on BTF Governance",
      q4: "Launch of Bee Trade Finance Native Blockchain",
    },
  ];

  return (
    <>
      {ROADMAP_DATA.map((rd, i) => (
        <div className="rounded-lg border border-ebony bg-mirage p-5 duration-300 ease-in-out hover:bg-blue-700 sm:bg-mirage md:bg-mirage lg:bg-ebony" key={i}>
          <div className="">
            <div className="text-center text-xl font-semibold lg:text-[26px]  lg:leading-10">
              {rd.year}
            </div>
            <ul className="mt-3 px-4 text-base leading-8 text-mercury text-opacity-90  lg:mt-4 lg:text-[22px]">
              <li className="list-disc">{rd.q1}</li>
              <li className="list-disc">{rd.q2}</li>
              <li className="list-disc">{rd.q3}</li>
              <li className={`${rd.q4 ? "list-disc" : ""}`}>{rd.q4}</li>
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};
