import React from "react";
import Chart from "react-google-charts";

export const data = [
    ["Task", "Hours per Day"],
  ["PD & Governence", 20],
  ["Charity", 3],
  ["Airdrops", 1],
  ["Liquidity", 36],
  ["Burning", 30],
  ["Locked", 10], // CSS-style declaration
];

export const options = {
  pieHole: 0.4,
  is3D: false,
};

export const Tokenomics = () => {
  return (
    <div className="container mx-auto">
      <div className="mx-4 mt-20 sm:mx-4 sm:mt-20 md:mx-4 md:mt-16 lg:mx-6 2xl:mx-0">
        <div className="rounded-xl lg:bg-mirage">
          <div className="lg:px-16 lg:py-14">
            <div className="text-center text-2xl font-semibold sm:text-2xl md:text-2xl lg:text-[45px] lg:leading-[50px] ">
              Tokenomics
            </div>
            <div className="md:flex md:justify-between pt-10">
              <div className="flex items-center md:items-start flex-col gap-4">
                <div className="flex text-lg sm:text-2xl md:text-lg xl:text-2xl">
                  <h1>Total Supply : &nbsp;&nbsp;</h1>
                  <p>1,080,000,000</p>
                </div>
                <div className="flex text-lg sm:text-2xl md:text-lg xl:text-2xl">
                  <h1>Liquidity : &nbsp;&nbsp;</h1>
                  <p>36%</p>
                </div>
                <div className="flex text-lg sm:text-2xl md:text-lg xl:text-2xl">
                  <h1>Burning : &nbsp;&nbsp;</h1>
                  <p>30%</p>
                </div>
                <div className="flex text-lg sm:text-2xl md:text-lg xl:text-2xl">
                  <h1>Locked : &nbsp;&nbsp;</h1>
                  <p>10%</p>
                </div>
                <div className="flex text-lg sm:text-2xl md:text-lg xl:text-2xl">
                  <h1>PD & Governence : &nbsp;&nbsp;</h1>
                  <p>20%</p>
                </div>
                <div className="flex text-lg sm:text-2xl md:text-lg xl:text-2xl">
                  <h1>Charity : &nbsp;&nbsp;</h1>
                  <p>3%</p>
                </div>
                <div className="flex text-lg sm:text-2xl md:text-lg xl:text-2xl">
                  <h1>Airdrops : &nbsp;&nbsp;</h1>
                  <p>1%</p>
                </div>
                <div className="flex text-lg sm:text-2xl md:text-lg xl:text-2xl">
                  <h1>Chain : &nbsp;&nbsp;</h1>
                  <p>Avalanche C-Chain</p>
                </div>
                <div className="flex text-lg sm:text-2xl md:text-lg xl:text-2xl">
                  <h1>Token Type : &nbsp;&nbsp;</h1>
                  <p>ERC-20</p>
                </div>
              </div>
              <div>
                <Chart
                  chartType="PieChart"
                  width="500px"
                  height="100%"
                  data={data}
                  options={options}
                  style={{ margin: 'auto' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
