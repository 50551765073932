import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "animate.css";
import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
import Landing from "./pages/Landing";
import { useState } from "react";

const HybridDex = [
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 },
  { id: 6 },
];
const Features = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];

function App() {
  const [open, setOpen] = useState(false);

  window.onscroll = () => {
    if (window.scrollY >= 600) {
      console.log("first", window.scrollY);
      for (let i = 0; i < 4; i++) {
        let div_name = document.getElementsByClassName("for_animation");
        // console.log("first", div_name);
        let index = i + 1;
        div_name[i].classList.add(
          `!visible`,
          `animate__animated`,
          `animate__bounceInUp`,
          `div${index}`
        );
      }
    }

    if (window.scrollY >= 1500 && window.scrollY <= 1600) {
      HybridDex.forEach((data, index) => {
        let div_name = document.getElementsByClassName("for_animation_dex");
        let i = index + 1;
        div_name[index].classList.add(
          `!visible`,
          `animate__animated`,
          `animate__bounceInUp`,
          `div${i}`
        );
      });
    }

    if (window.scrollY >= 3600) {
      Features.forEach((data, index) => {
        console.log("index", index);
        let div_name = document.getElementsByClassName("for_animation_feature");
        console.log("index", div_name);
        let i = index + 1;
        div_name[index].classList.add(
          `!visible`,
          `animate__animated`,
          `animate__bounceInUp`,
          `div${i}`
        );
      });
    }
  };

  return (
    <div className="flex flex-col bg-cinder font-clashdisplay text-white">
      <BrowserRouter>
        <Header setOpen={setOpen} open={open} />
        <div className={`bg-image flex-1  ${open ? "hidden" : "block"}`}>
          <Routes>
            <Route path="/" element={<Landing open={open} />} />
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
