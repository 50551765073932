import React from "react";
import { RoadmapCard } from "./RoadmapCard";

export const RoadMap = () => {
  return (
    <div className="container mx-auto">
      <div className="mx-4 mt-20 sm:mx-4 sm:mt-20 md:mx-4 md:mt-16 lg:mx-6 2xl:mx-0">
        <div className="rounded-xl lg:bg-mirage">
          <div className="lg:px-16 lg:py-14">
            <div className="text-center text-2xl font-semibold sm:text-2xl md:text-2xl lg:text-[45px] lg:leading-[50px] ">
              Roadmap
            </div>
            <div className="mt-8 sm:mt-8 md:mt-8 lg:mt-16">
              <div className="grid grid-cols-1 gap-[22px] sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
                <RoadmapCard />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
