import classNames from "classnames";
import React from "react";

export default function Button({ className, disabled, children, onClick }) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classNames(
        `font-semibold tracking-wide text-base leading-5 capitalize ${className} duration-300 ease-in-out ${disabled? "bg-[#333332] text-[#6B6969] cursor-not-allowed" : "bg-button text-alto"}`
      )}
    >
      {children}
    </button>
  );
}
