import classNames from "classnames";
import React from "react";
import Button from "./Button";
import DisplayCard from "./DisplayCard";

const DATA = [
  {
    id: 1,
    title: "Cross-Chain",
    subTitle:
      "The cross-chain protocol facilitates interoperability between different blockchain networks.",
    img: "chain",
  },
  {
    id: 2,
    title: "Proof of Stake",
    subTitle:
      "Proof of Stake is a popular, alternative consensus mechanism to Proof of Work.",
    img: "Pos",
  },
  {
    id: 3,
    title: "Permissionless",
    subTitle:
      "No one can stop you from using cryptocurrency. Centralized payment services can freeze accounts or prevent transactions from being made.",
    img: "like",
  },
  {
    id: 4,
    title: "A  cheap &  fast  payment method",
    subTitle: "Lower transaction fees and fastest transaction.",
    img: "medal",
  },
];

export default function Pioneering() {
  return (
    <>
      <div className="pioneering-bg">
        {/* <div className="relative">
        <div className="pioneering-bg "></div>
      </div> */}
        <div className="container relative mx-auto">
          <div className="z-30 mx-4 mt-9 sm:mx-4 sm:mt-9 md:mx-4 md:mt-9 lg:mx-6 lg:mt-16 2xl:mx-0">
            <div className=" bg-opacity-50 py-4">
              <div className="block flex-col justify-between rounded-xl bg-mirage px-4 py-8 sm:block sm:flex-row sm:py-8 sm:px-4 md:py-12 md:px-7 lg:flex lg:py-14 lg:px-10">
                <div className=" w-full sm:w-full md:w-full lg:w-[45%] 2xl:w-2/4">
                  <div className=" text-center text-2xl font-semibold leading-[30px] tracking-wider sm:text-center sm:text-2xl sm:leading-[30px] md:sm:text-2xl md:text-center md:leading-[30px] lg:text-start lg:text-[45px] lg:leading-[50px]">
                    Pioneering DeFi with cross-chain innovations
                  </div>
                  <div className="mt-3 text-center text-base text-soft-peach sm:text-center sm:text-base md:text-center md:text-base lg:text-start lg:text-[22px] lg:leading-9 ">
                    Bee Trade Finance Network is a Fully decentralized
                    peer-to-peer hybrid cryptocurrency exchange for the
                    Avalanche DeFi ecosystem built on EVM.
                  </div>
                  <a href="https://exchange.beetrade.net" target="_blank">
                    <div className="hidden sm:hidden lg:block">
                      <div className="my-16 w-[374px]">
                        <Button
                          className={classNames(
                            "w-full rounded-full py-5 sm:w-full lg:w-full"
                          )}
                        >
                          Launch App
                        </Button>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="w-full justify-end sm:w-full md:w-full lg:w-2/5">
                  <div className="my-5 flex w-full flex-col gap-3 sm:px-10">
                    {DATA.map((data) => (
                      <DisplayCard data={data} key={data.id} />
                    ))}
                  </div>
                  <a href="https://exchange.beetrade.net" target="_blank">
                    <div className="my-8 mx-auto block w-full sm:block sm:w-full  md:w-96 lg:hidden">
                      <Button
                        className={classNames(
                          "w-full rounded-full py-5 sm:w-full lg:w-full"
                        )}
                      >
                        Launch App
                      </Button>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
