import classNames from "classnames";
import React from "react";

import btf_staking from "../assets/images/btf_staking.svg";
import trade from "../assets/images/trade.svg";
import hybrid_crypto_currency from "../assets/images/hybrid_crypto_currency.svg";
import pool from "../assets/images/pool.svg";
import farm from "../assets/images/farm.svg";
import multi_chain from "../assets/images/multi_chain.svg";
import Button from "./Button";

const HybridDex = [
  {
    id: 1,
    dexImage: btf_staking,
    title: "$BTF Staking",
    subtitle:
      "Stake your $BTF to earn rewards. And get exclusive access to benefits.",
    btnText: "Stake BTF",
    href: "https://exchange.beetrade.net/",
  },
  {
    id: 2,
    dexImage: trade,
    title: "Trade",
    subtitle: "Swap or Trade quickly at the lowest fees.",
    btnText: "Launch Trade",
    href: "https://exchange.beetrade.net/trade",
  },
  {
    id: 3,
    dexImage: hybrid_crypto_currency,
    title: "Hybrid DEX",
    subtitle:
      "Combining a high-performance order book with Automated Market Making (AMM)",
    btnText: "Launch App",
    href: "https://exchange.beetrade.net/Swap",
  },
  {
    id: 4,
    dexImage: pool,
    title: "Pool",
    subtitle: "Provide liquidity for your favourite tokens and earn",
    btnText: "Launch Pool",
    href: "https://exchange.beetrade.net/pool",
  },
  {
    id: 5,
    dexImage: farm,
    title: "Farm",
    subtitle: "Earn extra yield by depositing your Liquidity Pool tokens.",
    btnText: "Coming Soon",
    disabled: true,
  },
  {
    id: 6,
    dexImage: multi_chain,
    title: "Multi-Chain Dashboard",
    subtitle: "Easily track your Web3 portfolio & access yield opportunities.",
    btnText: "Coming Soon",
    disabled: true,
  },
];

export default function HybridDexCard() {
  return (
    <>
      {HybridDex.map((data) => (
        <div className="for_animation_dex animate__fast hover_div invisible cursor-pointer rounded-lg border border-ebony bg-mirage p-[22px] duration-300 ease-in-out hover:bg-blue-700 sm:bg-mirage md:bg-mirage lg:bg-ebony">
          <div className="child_div flex items-center justify-between md:h-full md:flex-col lg:flex-col">
            <div className="super_child_div flex h-24 w-24 items-center justify-center">
              <img src={data.dexImage} alt={data.title} className="h-20 w-20" />
            </div>
            <div className="ml-4 md:text-center lg:text-center">
              <div className="text-xl font-semibold lg:text-[26px]  lg:leading-10">
                {data.title}
              </div>
              <div className="mt-3 text-base leading-8 text-mercury text-opacity-90  lg:mt-4 lg:text-[22px]">
                {data.subtitle}
              </div>
            </div>
            <div className="mt-5 hidden w-full justify-center disabled:cursor-not-allowed disabled:bg-slate-100 sm:hidden md:flex lg:mt-10 lg:flex ">
              <a
                className={classNames("flex w-full justify-center")}
                href={data.href}
                target="_blank"
              >
                <Button
                  className={classNames("w-3/5 rounded-full py-3")}
                  disabled={data.disabled}
                >
                  {data.btnText}
                </Button>
              </a>
            </div>
          </div>
          <div className="mt-5 block w-full sm:block md:hidden lg:mt-10 lg:hidden">
            <a
              className={classNames("flex w-full justify-center")}
              href={data.href}
              target="_blank"
            >
              <Button
                className={classNames("w-full rounded-full py-3")}
                disabled={data.disabled}
              >
                {data.btnText}
              </Button>
            </a>
          </div>
        </div>
      ))}
    </>
  );
}
