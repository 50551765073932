import React, { useRef } from "react";
import chain from "../assets/images/chain.svg";
import like from "../assets/images/like.svg";
import medal from "../assets/images/medal.svg";
import Pos from "../assets/images/Pos.svg";
import "animate.css";

export default function DisplayCard({ data }) {
  const scroll_ref = useRef();

  return (
    <>
      <div
        className={`hover_div for_animation invisible flex cursor-pointer rounded-md border border-gray-500 p-4 duration-100 ease-in hover:bg-blue-700`}
        ref={scroll_ref}
      >
        <div className="flex justify-center w-1/4 p-2 child_div">
          <div className="flex items-center justify-center w-20 h-20 super_child_div">
            <div className="h-14 w-14">
              <img
                src={
                  data.img === "chain"
                    ? chain
                    : data.img === "like"
                    ? like
                    : data.img === "medal"
                    ? medal
                    : Pos
                }
                alt={data.title}
                className="w-full"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-start w-3/4 pl-2">
          <div className="text-lg font-semibold font-clashdisplay">
            {data.title}
          </div>
          <div className="font-clashdisplay text-sm text-[#E6E6E6]">
            {data.subTitle}
          </div>
        </div>
      </div>
    </>
  );
}
