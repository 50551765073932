import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, NavLink } from "react-router-dom";
import logo_with_text from "../../assets/images/logo.svg";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";

import Button from "../Button";

const menu = [
  {
    name: "Ecosystem",
    href: "/ecosystem",
  },
  {
    name: "Launchpad",
    href: "/launchpad",
  },
  {
    name: "Education",
    href: "/education",
  },
  {
    name: "Roadmap",
    href: "/roadmap",
  },
  {
    name: "Docs",
    href: "/docs",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header({ setOpen, open }) {
  return (
    <div className="bg-header sticky top-0 overflow-hidden text-white">
      <header className="container mx-auto ">
        <div
          className={`mx-4 py-4 sm:mx-4 md:mx-4 lg:mx-6 2xl:mx-0 ${
            open && "h-screen"
          }`}
        >
          <Disclosure as="nav" className="">
            {({ open }) => {
              setOpen(open);
              return (
                <>
                  <div className="flex justify-between">
                    <div className={`flex ${open ? "hidden" : "block"}`}>
                      <div className="flex lg:flex-1">
                        <Link to="/" className="-m-1.5 p-1.5">
                          <div className=" flex items-center gap-2">
                            <Logo />
                            <div className="text-sm capitalize tracking-wide">
                              Bee-Trade finance
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                    {/* <div className="flex items-center lg:hidden">
                      <a href="https://exchange.beetrade.net" target="_blank">
                        <div className="flex justify-center">
                          <Button
                            className={classNames("rounded-lg py-[10px] px-6")}
                          >
                            Launch App
                          </Button>
                        </div>
                      </a>
                    </div> */}
                    {/* <div className="flex items-center">
                      <div className="hidden lg:ml-6 lg:flex lg:items-center">
                        <div className="hidden lg:-my-px lg:ml-6 lg:flex lg:space-x-8">
                          {menu.map((item) => (
                            <NavLink
                              key={item.name}
                              to={item.href}
                              end
                              className={({ isActive }) =>
                                classNames(
                                  isActive
                                    ? "border-aquamarine"
                                    : "hover:border-aquamarine border-transparent",
                                  "text-base font-medium capitalize leading-5 tracking-[0.016em] text-nobel"
                                )
                              }
                            >
                              {item.name}
                            </NavLink>
                          ))}
                        </div>
                      </div>
                    </div> */}
                    <div className="flex items-center">
                      <a href="https://exchange.beetrade.net" target="_blank">
                        <div className=" lg:-my-px lg:ml-6 lg:flex lg:space-x-8">
                          <Button
                            className={classNames(
                              "rounded-lg py-2 px-4 sm:py-[10px] sm:px-6"
                            )}
                          >
                            Launch App
                          </Button>
                        </div>
                      </a>
                      <div className="hidden sm:block sm:flex-shrink-0"></div>
                      {/* <div className="-mr-2 flex items-center lg:hidden">
                        <Disclosure.Button className="e inline-flex items-center justify-center rounded-md p-2 focus:outline-none focus:ring-0 focus:ring-indigo-500 focus:ring-offset-0">
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <XMarkIcon
                              className="block h-6 w-6 focus:ring-offset-0"
                              aria-hidden="true"
                            />
                          ) : (
                            <Bars3Icon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </Disclosure.Button>
                      </div> */}
                    </div>
                  </div>

                  <Disclosure.Panel className="bg-header-mobile animate__animated animate__slideInDown animate__faster absolute left-0 right-0 top-16 flex h-screen flex-col justify-around lg:hidden">
                    <div className="absolute -top-14 left-5">
                      <div className="flex lg:flex-1">
                        <Link to="/" className="-m-1.5 p-1.5">
                          <div className="flex gap-[10px]">
                            <img
                              className="h-[70px] w-auto"
                              src={logo_with_text}
                              alt="Bee-Trade Finance"
                            />
                          </div>
                        </Link>
                      </div>
                    </div>
                    {/* <div className="space-y-1 pt-2 pb-3 text-center">
                        {menu.map((item) => (
                          <NavLink
                            key={item.name}
                            as="a"
                            to={item.href}
                            end
                            className={({ isActive }) =>
                              classNames(
                                isActive
                                  ? ""
                                  : "hover:bg-aquamarine-400 border-transparent text-nobel hover:border-gray-300 hover:text-gray-800",
                                "block py-2 pl-3 pr-4 text-base font-medium"
                              )
                            }
                          >
                            {" "}
                            {item.name}
                          </NavLink>
                        ))}
                      </div> */}
                    <a href="https://exchange.beetrade.net" target="_blank">
                      <div className="flex justify-center">
                        <Button
                          className={classNames("rounded-lg py-[10px] px-6")}
                        >
                          Launch App
                        </Button>
                      </div>
                    </a>
                  </Disclosure.Panel>
                </>
              );
            }}
          </Disclosure>
        </div>
      </header>
    </div>
  );
}
