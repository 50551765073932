import classNames from "classnames";
import React from "react";
import twitter from "../assets/images/twitter.svg";
import telegram from "../assets/images/telegram.svg";
import facebook from "../assets/images/facebook.png";
import Button from "./Button";

export default function Community() {
  return (
    <div className="container mx-auto">
      <div className="mx-4 mt-10 sm:mx-10 sm:mt-20 md:mx-4 md:mt-32 lg:mx-6 2xl:mx-0">
        <div className="mb-5  ">
          <div className="relative">
            <div className="community-bg top-[-130px] h-[618px] "></div>
          </div>
          <div className="">
            <div className="mb-4 text-center text-[32px] font-semibold  lg:text-[26px] lg:leading-10">
              Community
            </div>
            <div className="text-center text-lg leading-8 text-[#E6E6E6]">
              Get involved and stay up to date. Access exclusive airdrops,
              giveaways, and events.{" "}
            </div>
          </div>
        </div>
        <div className="block w-full gap-4 sm:block md:block lg:flex ">
          <div className="mt-10 h-[373px] w-full rounded-lg bg-blue-bayoux bg-opacity-30 py-12 px-12 sm:mt-10 sm:w-full sm:bg-blue-bayoux sm:bg-opacity-30 md:mt-10 md:w-full md:bg-blue-bayoux md:bg-opacity-30 lg:mt-0 lg:w-2/6 lg:bg-woodsmoke lg:bg-opacity-100 ">
            <div className="flex flex-col items-center justify-center">
              <div>
                <img src={twitter} alt="twitter" className="h-20 w-20" />
              </div>
              <div className="text-center">
                <p className="mt-7 text-[34px] font-semibold leading-[51px] ">
                  35k
                </p>
                <p className="mt-2 text-xl font-medium tracking-wider text-mercury text-opacity-90">
                  Twitter Network
                </p>
              </div>
              <a
                href="https://twitter.com/BeeTradeChain?s=09"
                target="_blank"
                className="w-full"
              >
                <div className="mt-6 flex w-full justify-center">
                  <Button className={classNames("w-3/5 rounded-full py-3")}>
                    Follow Us
                  </Button>
                </div>
              </a>
            </div>
          </div>
          <div className="mt-10 h-[373px] w-full rounded-lg bg-blue-bayoux bg-opacity-30 py-12 px-12   sm:mt-10 sm:w-full sm:bg-blue-bayoux sm:bg-opacity-30 md:mt-10 md:w-full md:bg-blue-bayoux md:bg-opacity-30 lg:mt-0 lg:w-2/6 lg:bg-woodsmoke lg:bg-opacity-100 ">
            <div className="flex flex-col items-center justify-center">
              <div>
                <img src={telegram} alt="telegram" className="h-20 w-20" />
              </div>
              <div className="text-center">
                <p className="mt-7 text-[34px] font-semibold leading-[51px] ">
                  27.7k
                </p>
                <p className="mt-2 text-xl font-medium tracking-wider text-mercury text-opacity-90">
                  Telegram Network
                </p>
              </div>
              <a
                href="https://t.me/BTFannouncement"
                target="_blank"
                className="w-full"
              >
                <div className="mt-6 flex w-full justify-center">
                  <Button className={classNames("w-3/5 rounded-full py-3")}>
                    Join Telegram
                  </Button>
                </div>
              </a>
            </div>
          </div>
          <div className="mt-10 h-[373px] w-full rounded-lg bg-blue-bayoux bg-opacity-30 py-12 px-12   sm:mt-10 sm:w-full sm:bg-blue-bayoux sm:bg-opacity-30 md:mt-10 md:w-full md:bg-blue-bayoux md:bg-opacity-30 lg:mt-0 lg:w-2/6 lg:bg-woodsmoke lg:bg-opacity-100 ">
            <div className="flex flex-col items-center justify-center">
              <div>
                <img src={facebook} alt="facebook" className="h-20" />
              </div>
              <div className="text-center">
                <p className="mt-7 text-[34px] font-semibold leading-[51px] ">
                  9.9k
                </p>
                <p className="mt-2 text-xl font-medium tracking-wider text-mercury text-opacity-90">
                  Facebook Followers
                </p>
              </div>
              <a
                href="https://www.facebook.com/profile.php?id=100093087086814&mibextid=ZbWKwL"
                target="_blank"
                className="w-full"
              >
                <div className="mt-6 flex w-full justify-center">
                  <Button className={classNames("w-3/5 rounded-full py-3")}>
                    Join Us
                  </Button>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}
