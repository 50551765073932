import React from "react";
import avalanche from "../assets/images/avalanche.svg";

export default function DataVolume() {
  return (
    <div className="bg-cinder-light py-[66px] max-lg:mt-5 lg:mt-10">
      <div className="flex flex-col gap-5 lg:flex-row lg:items-center">
        <div className="built_on flex w-max items-center gap-[10px] bg-mirage py-[30px] pr-[70px] pl-[80px]">
          <div className="text-base font-semibold tracking-wide text-gallery sm:text-2xl lg:text-2xl">
            Built on
          </div>
          <div className="flex items-center gap-2">
            <img src={avalanche} alt="avalanche" className="max-sm:w-10" />
            <div className="text-base text-[#E84142] sm:text-2xl lg:text-2xl">
              Avalanche
            </div>
          </div>
        </div>
        <div className="trade mx-auto flex flex-row items-center justify-around">
          <div className="flex flex-row items-center justify-center ">
            <div className="flex flex-col items-center justify-center border-r border-alto p-5">
              <div className="money_title text-clashdisplay text-3xl font-bold sm:text-6xl">
                $500k
              </div>
              <div className="volume text-center text-2xl font-semibold text-martini">
                Trade Volume
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-center border-r border-alto">
            <div className="flex flex-col items-center justify-center p-5">
              <div className="money_title text-clashdisplay text-3xl font-bold sm:text-6xl">
                12M+
              </div>
              <div className="volume text-center text-2xl font-semibold text-martini">
                All Time Trades
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-col items-center justify-center p-5">
              <div className="money_title text-clashdisplay text-3xl font-bold sm:text-6xl">
                100+
              </div>
              <div className="volume text-2xl font-semibold text-martini">
                Integrations
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
