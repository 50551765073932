import React from "react";
import support_1 from "../assets/images/support_1.svg";
import support_2 from "../assets/images/support_2.svg";
import support_3 from "../assets/images/support_3.svg";
import support_4 from "../assets/images/support_4.svg";
import support_5 from "../assets/images/support_5.svg";
import support_6 from "../assets/images/support_6.svg";
import support_7 from "../assets/images/support_7.svg";
import support_8 from "../assets/images/support_8.svg";
import support_9 from "../assets/images/support_9.svg";
import certik from "../assets/images/certik.svg";
import avalanche_logo from "../assets/images/avalanche_logo.svg";

export default function KeySupporters() {
  return (
    <div className="container mx-auto">
      <div className="mt-16 sm:mt-16 md:mt-16 lg:mt-32">
        <div className="text-center text-3xl font-semibold leading-5 sm:text-[32px] sm:leading-5 md:text-[32px] md:leading-5 lg:text-[52px] lg:leading-[50px] ">
          Our Key Supporters
        </div>
        <div className="mt-8 sm:mt-8 md:mt-8 lg:mt-20 ">
          <div className="flex justify-center">
            <img
              src={support_1}
              alt="support_1"
              className="w-40 sm:w-40 md:h-auto md:w-auto lg:h-auto lg:w-auto"
            />
            <img
              src={support_2}
              alt="support_2"
              className=" w-40  sm:w-40 md:h-auto md:w-auto lg:h-auto lg:w-auto"
            />
            <img
              src={support_3}
              alt="support_3"
              className=" w-40  sm:w-40 md:h-auto md:w-auto lg:h-auto lg:w-auto"
            />
          </div>
          <div className="flex justify-between">
            <img
              src={support_4}
              alt="support_4"
              className=" w-40  sm:w-40 md:h-auto md:w-auto lg:h-auto lg:w-auto"
            />
            <img
              src={support_5}
              alt="support_5"
              className=" w-40  sm:w-40 md:h-auto md:w-auto lg:h-auto lg:w-auto"
            />
            <img
              src={support_6}
              alt="support_6"
              className=" w-40 sm:w-40 md:h-auto md:w-auto lg:h-auto lg:w-auto"
            />
            <img
              src={support_7}
              alt="support_7"
              className=" w-40 sm:w-40 md:h-auto md:w-auto lg:h-auto lg:w-auto"
            />
          </div>
          <div className="flex justify-center">
            <img
              src={support_8}
              alt="support_8"
              className=" w-40 sm:w-40 md:h-auto md:w-auto lg:h-auto lg:w-auto"
            />
            <img
              src={support_9}
              alt="support_9"
              className=" w-40  sm:w-40 md:h-auto md:w-auto lg:h-auto lg:w-auto"
            />
            <img
              src={support_1}
              alt="support_1"
              className=" w-40  sm:w-40 md:h-auto md:w-auto lg:h-auto lg:w-auto"
            />
          </div>
          <div className="flex justify-evenly">
            <img
              src={certik}
              alt="certik"
              className="w-40 sm:w-40 md:h-auto md:w-auto lg:h-auto lg:w-auto"
            />
            <img
              src={avalanche_logo}
              alt="avalanche_logo"
              className=" w-40  sm:w-40 md:h-auto md:w-auto lg:h-auto lg:w-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
