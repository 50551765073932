import React from "react";
import { Link } from "react-router-dom";

const Apps = [
  {
    id: 1,
    title: "Trade",
    href: "https://exchange.beetrade.net/trades",
  },
  {
    id: 2,
    title: "swap",
    href: "https://exchange.beetrade.net/Swap",
  },
  {
    id: 3,
    title: "pool",
    href: "https://exchange.beetrade.net/pool",
  },
  {
    id: 4,
    title: "farm",
    href: "https://exchange.beetrade.net/",
  },
];
const Companies = [
  {
    id: 1,
    title: "docs",
    href: "https://beetradefinance.com/wp-content/uploads/2023/07/Beetrade-white-paper-Feb-2023_1.0.pdf",
  },
  {
    id: 2,
    title: "Email",
    href: "mailto:info@beetrade.net",
  },
  {
    id: 3,
    title: "Youtube",
    href: "https://youtube.com/channel/UCQyXoMQxQBdZSrBiRfDdZoQ",
  },
];
const Communities = [
  {
    id: 1,
    title: "twitter",
    href: "https://twitter.com/BeeTradeChain?s=09",
  },
  {
    id: 2,
    title: "Linkedin",
    href: "https://www.linkedin.com/groups/14038071",
  },
  {
    id: 3,
    title: "Telegram",
    href: "https://t.me/BTFannouncement",
  },
  {
    id: 4,
    title: "Instagram",
    href: "https://www.instagram.com/invites/contact/?i=gmpj6775czyn&utm_content=nc971s2",
  },
  {
    id: 5,
    title: "Facebook",
    href: "https://www.facebook.com/profile.php?id=100093087086814&mibextid=ZbWKwL",
  },
];

export default function FooterLink() {
  return (
    <div className="flex w-full flex-wrap">
      <div className="w-2/4 sm:w-2/4 md:w-1/3 lg:w-1/3">
        <div>
          <div className="text-[26px] font-semibold leading-[50px] tracking-wider text-[#DBDBDB] ">
            App
          </div>
          <div className="mt-5">
            {Apps.map((app) => {
              return (
                <a href={app.href} target="_blank">
                  <p className=" mb-4 text-2xl font-normal capitalize leading-[50px] tracking-wider text-[#C2C2C2] ">
                    {app.title}
                  </p>
                </a>
              );
            })}
          </div>
        </div>
      </div>
      <div className="w-2/4 sm:w-2/4 md:w-1/3 lg:w-1/3">
        <div>
          <div className="text-[26px] font-semibold leading-[50px] tracking-wider text-[#DBDBDB] ">
            Company
          </div>
          <div className="mt-5">
            {Companies.map((company) => (
              <a href={company.href} target="_blank">
                <p className=" mb-4 text-2xl font-normal capitalize leading-[50px] tracking-wider text-[#C2C2C2] ">
                  {company.title}
                </p>
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="w-2/4 sm:w-2/4 md:w-1/3 lg:w-1/3">
        <div>
          <div className="text-[26px] font-semibold leading-[50px] tracking-wider text-[#DBDBDB] ">
            Community
          </div>
          <div className="mt-5">
            {Communities.map((community) => (
              <a href={community.href} target="_blank">
                <p className=" mb-4 text-2xl font-normal capitalize leading-[50px] tracking-wider text-[#C2C2C2] ">
                  {community.title}
                </p>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
