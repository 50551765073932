import classNames from "classnames";
import React, { useEffect, useState } from "react";
import heroimage from "../assets/images/heroimage.svg";
import heroimage2 from "../assets/images/heroimg_2.svg";
import certik from "../assets/images/certik.svg";
import verify from "../assets/images/verify.svg";
import Button from "./Button";
import DataVolume from "./DataVolume";

export default function HeroSection({ open }) {
  const [text, setText] = useState("");
  const [fullText, setFullText] = useState(
    "The Future of Multi and Cross Chain Defi"
  );
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < fullText.length) {
      setTimeout(() => {
        setText(text + fullText[index]);
        setIndex(index + 1);
      }, 30);
    }
  }, [index, setFullText]);

  return (
    <div className="container mx-auto">
      <div className="mx-4 mt-8 sm:mx-4 sm:mt-8 md:mx-4 md:mt-8 lg:mx-6 lg:mt-[134px] 2xl:mx-0">
        <div
          className={`relative block sm:block md:flex lg:flex ${
            open ? "-z-10" : ""
          }`}
        >
          <div className="w-full sm:w-full md:w-full lg:w-3/5">
            <div className="text-[42px] font-bold leading-[52px] tracking-wider sm:text-[42px] sm:leading-[52px] md:text-[42px] md:leading-[52px] lg:w-10/12 lg:text-[70px] lg:leading-[80px] ">
              {text}
            </div>
            <p
              className="animate__animated animate__bounceInUp animate__delay-2s mt-6 text-lg font-medium leading-8 tracking-wider sm:text-lg sm:leading-8 lg:w-2/3 lg:text-[22px] lg:leading-[38px]"
              // style={{ visibility: "visible", animationDelay: "0.7s" }}
            >
              Swap, earn, stake, all on one decentralized, community driven
              platform. Welcome to the fastest growing home to DeFi
            </p>
            <div className="mt-6 sm:mt-6 md:mt-6 lg:mt-12">
              <div className="block gap-8 sm:block md:block lg:flex">
                <a href="https://exchange.beetrade.net" target="_blank">
                  <div>
                    <Button
                      className={classNames(
                        "launch_app w-72 rounded-full py-6 sm:w-72 lg:w-72"
                      )}
                    >
                      Launch App
                    </Button>
                  </div>
                </a>
                <div className="mt-[22px] flex items-center gap-4 sm:mt-[22px] lg:mt-0">
                  <img
                    src={certik}
                    alt="certik"
                    className="h-auto w-[90px] sm:w-auto md:w-auto "
                  />
                  <div className="flex gap-3">
                    <img
                      src={verify}
                      alt="verify"
                      className="sm:auto h-auto w-[19px] md:w-auto"
                    />
                    <div className="flex items-center text-sm font-semibold leading-10 tracking-wider sm:text-[26px] md:text-[26px] ">
                      Audited
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero_img w-2/5 justify-end overflow-x-hidden max-md:absolute max-md:right-0 max-md:top-52 max-md:block max-md:flex-col md:flex">
            <div className="hero_child h-[458px] w-[483px] overflow-x-hidden max-md:h-auto max-md:w-auto">
              <img
                src={heroimage}
                alt="hero"
                className="ml-auto max-w-full overflow-x-hidden"
              />
            </div>
          </div>
        </div>
      </div>
      <DataVolume />
    </div>
  );
}
