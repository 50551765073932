import React from "react";
import BeeTradeHybridDex from "../components/BeeTradeHybridDex";
import Community from "../components/Community";
import Fetured from "../components/Fetured";
import HeroSection from "../components/HeroSection";
import KeySupporters from "../components/KeySupporters";
import Pioneering from "../components/Pioneering";
import { RoadMap } from "../components/RoadMap";
import { Tokenomics } from "../components/Tokenomics";

export default function Landing({ open }) {
  return (
    <div>
      <HeroSection open={open} />
      <Pioneering />
      <BeeTradeHybridDex />
      <Tokenomics />
      <RoadMap />
      <Community />
      <KeySupporters />
      {/* <Fetured /> */}
    </div>
  );
}
