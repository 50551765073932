import React from "react";
import { Link } from "react-router-dom";
import logo_with_text from "../../assets/images/logo_with_text.png";
import FooterLink from "./FooterLink";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";

export default function Footer() {
  return (
    <>
      <div className="relative">
        <div className="footer-gradient h-[618px] sm:h-[618px] md:h-[618px] lg:h-[455px] "></div>
      </div>
      <div className="relative z-10">
        <div className="container mx-auto">
          <div className="mx-4 mt-16 mb-6 text-white sm:mx-4 sm:mb-6 sm:mt-16 md:mx-4 md:mb-6 md:mt-20 lg:mx-6 lg:mt-40 lg:mb-28 ">
            <div className="block w-full flex-1 justify-between sm:block md:block lg:flex">
              <div className="2xl:w-[22%] ">
                <Link to="/" className="">
                  <div className=" items-center gap-2 flex">
                    <Logo />
                    <div className="text-sm capitalize tracking-wide">
                      Bee-Trade finance
                    </div>
                  </div>
                </Link>
                <div className="mt-6">
                  <div className="text-[32px] font-semibold leading-[50px] tracking-wider text-soft-peach ">
                    Subscribe
                  </div>
                  <div className="text-base font-normal leading-5 tracking-wider text-silver">
                    Subscribe to the our newsletter
                  </div>
                </div>
                <form>
                  <div className="mt-8">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="off"
                      className="w-full rounded-xl border border-dovegray bg-transparent py-1 px-4 text-lg font-medium leading-[50px] tracking-wider text-[#CBC8C8] outline-0 "
                      placeholder="Your Email"
                      required
                    />
                  </div>
                  <div className="mt-4 mb-11 w-full">
                    <button
                      className={
                        "bg-button w-full rounded-xl py-1 px-4 text-lg font-semibold capitalize leading-[50px] tracking-wider"
                      }
                    >
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
              <div className="lg:w-2/4 ">
                <FooterLink />
              </div>
            </div>
          </div>
          <div className="mb-6 text-center">
            <p className="hidden text-[22px] font-normal leading-[50px] tracking-wider text-[#C2C2C2] sm:hidden md:block ">
              © 2022 Beetrade. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
